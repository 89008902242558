<template>
  <div>故事列表</div>
</template>

<script>
export default {}
</script>

<style>
</style>
